// utils/firebaseErrorHandler.ts
export function getFirebaseErrorMessage(
  errorCode: string,
  t: (key: string) => string,
): string {
  console.log('errorCode', errorCode)
  const errorMessages: { [key: string]: string } = {
    'auth/invalid-email': t('firebaseErrors.invalidEmail'),
    'auth/user-disabled': t('firebaseErrors.userDisabled'),
    'auth/user-not-found': t('firebaseErrors.userNotFound'),
    'auth/wrong-password': t('firebaseErrors.wrongPassword'),
    'auth/email-already-in-use': t('firebaseErrors.emailAlreadyInUse'),
    'auth/operation-not-allowed': t('firebaseErrors.operationNotAllowed'),
    'auth/weak-password': t('firebaseErrors.weakPassword'),
    'auth/invalid-credential': t('firebaseErrors.invalidLoginCredentials'),
  }

  return errorMessages[errorCode] || t('firebaseErrors.unknownError')
}
